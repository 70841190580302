var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "access-config-component table-component" }, [
    _c(
      "div",
      { staticClass: "page-body page-body-margin-left-16 access-config-body" },
      [
        _c(
          "div",
          { staticClass: "slider-wrapper" },
          [
            _c("a-slider", {
              staticClass: "slider-master",
              attrs: { range: "", marks: { 0: "0%", 100: "100%" } },
              model: {
                value: _vm.accessConfig,
                callback: function ($$v) {
                  _vm.accessConfig = $$v
                },
                expression: "accessConfig",
              },
            }),
            _c("div", { staticClass: "customer-slider-track-2-wrapper" }, [
              _c("div", {
                staticClass: "customer-slider-track-2",
                style: _vm.Slider2TrackStyle,
              }),
            ]),
            _c(
              "div",
              [
                _c("sup", [_vm._v("*")]),
                _vm._v("正常(%) 小于等于: "),
                _c("a-input-number", {
                  model: {
                    value: _vm.accessConfig[0],
                    callback: function ($$v) {
                      _vm.$set(_vm.accessConfig, 0, $$v)
                    },
                    expression: "accessConfig[0]",
                  },
                }),
                _c("br"),
                _c("br"),
                _c("sup", [_vm._v("*")]),
                _vm._v("重要(%) 介于两个数之间: "),
                _c("a-input-number", {
                  model: {
                    value: _vm.accessConfig[0],
                    callback: function ($$v) {
                      _vm.$set(_vm.accessConfig, 0, $$v)
                    },
                    expression: "accessConfig[0]",
                  },
                }),
                _vm._v(" - "),
                _c("a-input-number", {
                  model: {
                    value: _vm.accessConfig[1],
                    callback: function ($$v) {
                      _vm.$set(_vm.accessConfig, 1, $$v)
                    },
                    expression: "accessConfig[1]",
                  },
                }),
                _c("br"),
                _c("br"),
                _c("sup", [_vm._v("*")]),
                _vm._v("紧急(%) 大于等于: "),
                _c("a-input-number", {
                  model: {
                    value: _vm.accessConfig[1],
                    callback: function ($$v) {
                      _vm.$set(_vm.accessConfig, 1, $$v)
                    },
                    expression: "accessConfig[1]",
                  },
                }),
                _c("br"),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
        _c("br"),
        _c(
          "jtl-button",
          {
            attrs: { type: "primary", "click-prop": _vm.saveClick.bind(this) },
          },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }